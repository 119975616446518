* {
    outline: none!important;
}
body, html {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: #2b2e44;
}
.loyalid-portal-login {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #ececec;;
    height: 100vh;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100px;
        width: 100vh;
        height: 100vh;
        background: linear-gradient(0deg, #ececec, white, #ececec);
        border-radius: 100%;
    }
    .left {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 50px;
        box-sizing: border-box;
        @media (max-width: 1000px) {
            display: none;
        }
        > div {
            position: relative;
            max-width: 600px;
            text-align: left;
            color: #717171;
            z-index: 1;
            h1 {
                display: inline-block;
                position: relative;
                font-size: 35px;
                &::after {
                    content: '';
                    position: absolute;
                    top: calc(100% + 5px);
                    left: 0;
                    width: 100%;
                    height: 8px;
                    border-radius: 5px;
                    background: #f9b113;
                    border-bottom: 2px solid #ffd679;
                }
            }
            p {
                font-size: 18px;
                font-weight: bold;
                line-height: 30px;
                letter-spacing: 1px;
            }
        }
        .circle {
            position: absolute;
            border-radius: 100%;
            background: #f9b113;
            border-bottom: 4px solid #ffd579;
            box-sizing: border-box;
            .inner {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                // background: #fff;
                // background: linear-gradient(0deg, #fff, transparent);
                background: linear-gradient(35deg, #fff, #ffecc4);
                border-top: 3px solid #e0a013;
                border-left: 2px solid #e0a013;
                box-sizing: border-box;
            }
            &.one {
                top: 80px;
                left: 60px;
                height: 80px;
                width: 80px;
                padding: 15px;
                transform: rotate(-30deg);
            }
            &.two {
                top: 250px;
                left: 500px;
                height: 60px;
                width: 60px;
                padding: 8px;
                transform: rotate(-70deg);
                .inner {
                    border-radius: 100%;
                }
            }
            &.three {
                bottom: 80px;
                left: 250px;
                height: 60px;
                width: 60px;
                padding: 10px;
                border-radius: 10px;
                transform: rotate(-45deg);
            }
            &.four {
                top: 80px;
                left: 750px;
                height: 30px;
                width: 60px;
                padding: 8px;
                border-radius: 15px;
                transform: rotate(45deg);
            }
            &.five {
                top: 80px;
                left: 750px;
                height: 30px;
                width: 60px;
                padding: 8px;
                border-radius: 15px;
                transform: rotate(-45deg);
            }
            &.six {
                top: 400px;
                left: 900px;
                height: 20px;
                width: 40px;
                padding: 5px;
                border-radius: 15px;
                transform: rotate(-45deg);
            }
            &.seven {
                bottom: 200px;
                left: 750px;
                height: 30px;
                width: 60px;
                padding: 8px;
                border-radius: 15px;
                transform: rotate(45deg);
            }
        }
    }
    .login-container {
        position: relative;
        flex: 0 0 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        z-index: 1;
        @media (max-width: 1000px) {
            flex: 0 0 100%;
        }
        .login-box-wrapper {
            position: relative;
            width: 400px;
            min-height: 550px;
            background: #fff;
            // box-shadow: -2vw 2vw 3vw rgba(17,99,154,0.21);
            border-radius: 25px;
            border-top: 4px solid #4a506f;
            border-bottom: 4px solid #e0a013;
            @media (max-width: 480px) {
                width: 100%;
                min-height: 100vh;
                border-radius: 0;
            }
        }
        .login-box {
            position: relative;
            width: 400px;
            height: 100%;
            background: #2b2f44;
            border-radius: 22px;
            border-bottom: 40px solid #f9b113;
            box-sizing: border-box;
            @media (max-width: 480px) {
                width: 100%;
                border-radius: 0;
                border-bottom: 80px solid #f9b113;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: -25px;
                left: 0;
                width: 100%;
                height: 25px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                background: #2b2f44;
                border-bottom: 3px solid #ffd579;
            }
            .logo {
                background: #2b2f44;
                text-align: center;
                padding-top: 30px;
                border-radius: 25px;
            }
            h3 {
                color: #fff;
                text-align: center;
                margin-bottom: 0;
                font-size: 18px;
            }
            form {
                position: relative;
                padding: 20px;
                z-index: 100;
                p {
                    color: #fff;
                    font-weight: bold;
                    margin: 8px 0 4px 0;
                    font-size: 14px;
                }
                input:not([type=checkbox]) {
                    width: 100%;
                    padding: 0 10px;
                    line-height: 30px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    border: 0;
                    font-size: 15px;
                    font-weight: bold;
                    border-bottom: 2px solid #5f688b;
                }
                .remember-me {
                    color: #fff;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 30px;
                    input {
                        margin: 0 5px 0 0;
                    }
                }
                .captcha {
                    display: flex;
                    justify-content: center;
                }
                button {
                    width: 100%;
                    line-height: 45px;
                    border-radius: 5px;
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    background: #4a506f;
                    border: 0;
                    border-bottom: 3px solid #60688c;
                    color: #fff;
                    cursor: pointer;
                    transition: all .3s ease;
                    &:hover {
                        background: #60688c;
                    }
                }
                .forgot-password {
                    display: block;
                    line-height: 25px;
                    border-radius: 5px;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: bold;
                    background: #b9242b;
                    border: 0;
                    border-bottom: 3px solid #ed1b25;
                    text-align: center;
                    color: #fff;
                    text-decoration: none;
                    cursor: pointer;
                    transition: all .3s ease;
                    &:hover {
                        background: #ed1b25;
                    }
                }
                .back-to-login {
                    display: block;
                    line-height: 25px;
                    border-radius: 5px;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: bold;
                    background: #b9242b;
                    border: 0;
                    border-bottom: 3px solid #ed1b25;
                    text-align: center;
                    color: #fff;
                    text-decoration: none;
                    cursor: pointer;
                    transition: all .3s ease;
                    &:hover {
                        background: #ed1b25;
                    }
                }
            }
            .error {
                color: #ff0000;
                margin-top: 5px;
                text-align: center;
            }
        }
    }
}
.loyalid-portal-app {
    display: flex;
    overflow: hidden;
    @media (max-width: 550px) {
        flex-wrap: wrap;
        .side-menu {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10000;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
            }
            .sub-menu {
                display: none!important;
            }
            &.open {
                overflow: unset;
                z-index: 100000;
            }
        }
        .menu-scroll-up {
            display: none;
        }
        .menu-scroll-down {
            display: none;
        }
        .all-menu-content {
            width: 100vw!important;
            .topbar {
                padding: 14px 16px!important;
            }
            .sub-menu {
                display: block!important;
            }
        }
        .main-menu {
            display: none!important;
        }
        .main-content {
            padding-left: 60px!important;
            .mobile-logo {
                display: block!important;
            }
            .breadcrumb-menu  {
                display: none;
            }
            .mobile-menu {
                display: block!important;
            }
            .topbar {
                position: relative!important;
                padding-top: 0!important;
            }
            .flex {
                flex-wrap: wrap-reverse;
                margin-bottom: 5px!important;
                .title {
                    width: 100%;
                    margin: 5px 0 0 0!important;
                    text-align: center;
                    font-size: 18px!important;
                    line-height: 30px!important;
                }
            }
            .account-container {
                flex-wrap: wrap-reverse;
                width: 100%;
                > div {
                    flex: 0 0 100%;
                }
                .mobile-client {
                    display: block!important;
                }
                .customer-info {
                    margin: 5px 0!important;
                    height: 100%!important;
                    .locked-customer {
                        flex-wrap: wrap;
                        > div {
                            flex: 0 0 100%;
                            border: 0!important;
                        }
                        .customer {
                            margin-bottom: 5px;
                            text-align: left!important;
                        }
                        .info {
                            text-align: left!important;
                        }
                    }
                }
                .account {
                    position: sticky!important;
                    top: 80px;
                    z-index: 10000;
                }
                .user {
                    flex-grow: 1;
                }
                .divider {
                    display: none;
                }
            }
        }
    }
    .side-menu {
        flex: 0 0 50px;
        width: 50px;
        height: 100vh;
        background: #2b2e44;
        // border-right: 1px solid #434863;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        box-sizing: border-box;
        .all-menu {
            position: sticky;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;
            background: #3b3f54;
            border: 1px solid #141418;
            border-radius: 5px;
            z-index: 1;
            cursor: pointer;
            .icon {
                width: 18px;
                height: 18px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        .all-menu-content {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 300px;
            z-index: 1000;
            background: #2b2f44;
            overflow-y: auto;
            opacity: 0;
            transform: translateX(-100%);
            transition: all .3s ease;
            &.open {
                opacity: 1;
                transform: translateX(0);
            }
            &::-webkit-scrollbar {
                display: none;
            }
            .topbar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: sticky;
                top: 0;
                background: #2c2f44;
                color: #fff;
                padding: 14px 0 14px 16px;
                z-index: 10000;
                > div {
                    display: flex;
                    align-items: center;
                }
                .icon {
                    width: 18px;
                    height: 18px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                span {
                    margin-left: 10px;
                    font-weight: bold;
                }
                button {
                    background: #4a506f;
                    border: 0;
                    border-bottom: 2px solid #60688c;
                    border-radius: 5px;
                    padding: 0 20px;
                    cursor: pointer;
                }
            }
            .footer {
                height: 50px;
            }
            .divider {
                position: sticky;
                top: 52px;
                height: 100%;
                margin: 0 -5px;
                padding: 0 15px;
                background: #434862;
                color: #fff;
                font-weight: bold;
                z-index: 10000;
                span {
                    display: block;
                    line-height: 40px;
                }
            }
            ul {
                height: unset;
            }
            li {
                flex-direction: column;
                &.active {
                    background: none!important;
                    border: unset!important;
                }
            }
            .menu {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                color: #fff!important;
                text-decoration: none;
                span {
                    display: block!important;
                    margin-left: 10px;
                }
            }
            .sub-menu {
                display: block;
                position: relative;
                left: 0;
                background: none;
                border: none;
                box-shadow: none;
                width: 100%;
                &::before {
                    content: none;
                }
                a {
                    padding: 0 5px 0 26px;
                    color: #fff!important;
                    &:hover {
                        color: #2b2f44!important;
                        font-weight: bold;
                    }
                }
                li.active {
                    background: #ffd579!important;
                    a {
                        color: #2b2f44!important;
                    }
                    .child-menu {
                        li:not(.active) a {
                            color: #fff!important;
                            &:hover {
                                color: #2b2f44!important;
                            }
                        }
                    }
                }
                .accord {
                    &::after {
                        right: 10px;
                        top: 14px;
                    }
                    &:hover > a {
                        color: #2b2f44!important;
                    }
                }
                .child-menu {
                    margin: 0;
                    background: #2c2f44;
                    max-height: unset;
                    border: 0;
                    a {
                        color: #fff;
                    }
                }
            }
        }
        .menu-scroll-up {
            position: fixed;
            top: 50px;
            left: 0;
            height: 20px;
            width: 50px;
            background: #3b3f54;
            border: 1px solid #141418;
            border-radius: 5px;
            z-index: 1;
            &.hide {
                display: none;
            }
            &::after {
                content: '';
                position: absolute;
                top: 7px;
                left: 50%;
                transform: translateX(-50%) rotate(-136deg);
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                width: 8px;
                height: 8px;
                transition: all .3s ease;
            }
        }
        .menu-scroll-down {
            position: fixed;
            bottom: 0;
            left: 0;
            height: 20px;
            width: 50px;
            background: #3b3f54;
            border: 1px solid #141418;
            border-radius: 5px;
            z-index: 1;
            &::after {
                content: '';
                position: absolute;
                top: 4px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                width: 8px;
                height: 8px;
                transition: all .3s ease;
            }
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 5px;
            box-sizing: border-box;
            height: 100vh;
            transition: all .3s ease;
            li {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 10px;
                margin: 5px 0;
                border-radius: 10px;
                border: 2px solid transparent;
                transition: all .5s ease;
                cursor: pointer;
                a {
                    line-height: normal;
                }
                .menu {
                    height: 18px;
                    .icon {
                        width: 18px;
                        height: 18px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    span {
                        display: none;
                    }
                }
                .sub-menu {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    min-width: 200px;
                    background: #fff;
                    border-radius: 5px;
                    border-left: 7px solid #353a52;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                    z-index: 1001;
                    &::before {
                        content: '';
                        position: absolute;
                        left: -8px;
                        top: 6px;
                        width: 0;
                        height: 0;
                        border: 8px solid transparent;
                        border-right-color: #fff;
                        border-left: 0;
                    }
                    ul {
                        height: 100%;
                        padding: 5px 0;
                        li {
                            display: block;
                            margin: 0;
                            padding: 0;
                            border-radius: 0;
                            cursor: pointer;
                            a, span {
                                display: block;
                                color: #2b2e44!important;
                                font-size: 14px;
                                font-weight: 500;
                                color: #000;
                                text-decoration: none;
                                line-height: 30px;
                                padding: 0 15px;
                                box-sizing: border-box;
                                white-space: nowrap;
                                user-select: none;
                                font-weight: normal;
                                &:visited,
                                &:hover,
                                &:active {
                                    color: inherit;
                                    text-decoration: inherit;
                                }
                            }
                            &.active {
                                background: #eee;
                                border-color: transparent;
                                a {
                                    font-weight: bold;
                                    color: #000;
                                }
                            }
                            &:hover {
                                background: #ffd579;
                                border-color: transparent;
                            }
                        }
                    }
                }
                .child-menu {
                    margin: -2px;
                    background: #f5f5f5;
                    border-right: 1px solid #6a6d7d;
                    border-left: 1px solid #6a6d7d;
                    ul {
                        padding: 0;
                        li {
                            padding-left: 10px;
                            &:hover {
                                background: #f9b113;
                            }
                        }
                    }
                }
                &:hover {
                    border: 2px solid #f9b115;
                    transition: all .5s ease;
                    .sub-menu {
                        display: block;
                    }
                }
                &.active {
                    position: relative;
                    background: linear-gradient(0deg, #9da5b0, transparent 10%, #9da5b0 250%);
                    border: 2px solid #141417;
                    // animation: menu-active 3s ease infinite;
                    cursor: default !important;
                }
            }
            .divider {
                height: 2px;
                background: #000;
                border-top: 2px solid #434863;
                span {
                    display: none;
                }
            }
        }
        .child-menu {
            display: none;
            max-height: 105px;
            overflow-y: auto;
        }
        .accord {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                right: 5px;
                top: 15px;
                border-right: 2px solid #4a506f;
                border-bottom: 2px solid #4a506f;
                width: 8px;
                height: 8px;
                transition: all .3s ease;
                transform: translateY(-50%) rotate(-45deg);
            }
            &.open {
                &::after {
                    transform: translateY(-65%) rotate(45deg);
                }
                .child-menu {
                    display: block;
                    a {
                        font-weight: normal;
                        &:hover {
                            font-weight: bold;
                        }
                    }
                    li.active a {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .main-menu {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0 0 250px;
        background: #f9b113;
        &::after {
            content: '';
            position: absolute;
            bottom: 54px;
            left: 0;
            width: 100%;
            height: 35px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            background: linear-gradient(0deg, #fff 10%, transparent);
            z-index: 1;
        }
        .top {
            flex: 0 0 115px;
            height: 115px;
            padding: 15px 15px 0 15px;
            box-sizing: border-box;
            padding-bottom: 25px;
            box-sizing: border-box;
            background: #2b2f44;
            .logo {
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        .menu-container {
            position: relative;
            flex-grow: 1;
            background: #fff;
            color: #000;
            border-radius: 25px;
            padding: 0 15px 25px 15px;
            box-sizing: border-box;
            margin-top: -25px;
            margin-bottom: 55px;
            height: calc(100vh - 145px);
            overflow-y: auto;
            .topbar {
                position: sticky;
                top: 0;
                background: #fff;
                margin-bottom: 20px;
                padding: 15px 0 20px 0;
                border-bottom: 1px solid #e4e4e4;
                box-sizing: border-box;
                z-index: 1;
                .access-level-title {
                    color: #000;
                    font-size: 13px;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-top: 10px;
                    line-height: 28px;
                }
                .dropdown-toggle, .chosen-single {
                    border-radius: 8px!important;
                    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                    overflow: hidden;
                    cursor: pointer;
                    border: 1px solid #e4e4e4!important;
                    color: #2b2e44!important;
                    box-shadow: none;
                }
                .dropdown-menu {
                    margin-top: 1px;
                    left: 0;
                    width: 100%;
                    padding: 5px 0;
                    box-sizing: border-box;
                    border-radius: 5px;
                    background: #fff;
                    border: 0;
                    color: #2b2e44;
                    font-size: 12px;
                    font-weight: 500;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                    z-index: 1000;
                    &.inner {
                        padding: 0;
                        box-shadow: none;
                        border-radius: 0;
                        li {
                            margin: 0;
                            padding: 0;
                            .icon {
                                display: none!important;
                            }
                            &.selected a {
                                background: #eee;
                                border-radius: 0;
                                &:hover {
                                    background: #ffd579;
                                }
                            }
                        }
                        a {
                            color: #2b2e44;
                            font-size: 13px;
                            &:hover {
                                background: #ffd579;
                                border-radius: 0;
                            }
                        }
                    }
                }
                .chosen-drop {
                    margin-top: 1px!important;
                    left: 0!important;
                    width: 100%!important;
                    padding: 5px 0!important;
                    box-sizing: border-box!important;
                    border-radius: 5px!important;
                    background: #fff!important;
                    border: 0!important;
                    color: #2b2e44!important;
                    font-size: 12px!important;
                    font-weight: 500!important;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)!important;
                    z-index: 1000!important;
                    .chosen-search input {
                        border-radius: 5px!important;
                        border: 1px solid #e4e4e4!important;
                        padding: 0 22px 0 6px!important;
                        &::after, &::before {
                            content: ''!important;
                        }
                    }
                    .chosen-results {
                        margin: 0;
                        padding: 0;
                        li {
                            font-size: 13px;
                            color: #2b2e44!important;
                            border-radius: 0!important;
                            margin: 0!important;
                            &:hover {
                                background: #ffd579!important;
                            }
                        }
                    }
                }
            }
            .menu-active {
                .divider {
                    display: none;
                }
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li {
                        display: none;
                    }
                    li.active {
                        display: block;
                        .menu {
                            display: flex;
                            align-items: center;
                            padding: 4px 8px;
                            box-sizing: border-box;
                            border-radius: 5px;
                            background: #4a506f;
                            color: #fff;
                            font-weight: bold;
                            margin-bottom: 10px;
                            text-decoration: none!important;
                            cursor: default;
                            .icon {
                                width: 18px;
                                height: 18px;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center center;
                                margin-right: 5px;
                            }
                        }
                        ul li {
                            display: block;
                            line-height: 30px;
                            padding-left: 12px;
                            box-sizing: border-box;
                            &.active {
                                font-weight: bold;
                            }
                            a {
                                display: inline-block;
                                width: 100%;
                                position: relative;
                                color: #2b2f44!important;
                                transition: all .3s ease;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: -12px;
                                    top: 50%;
                                    border-right: 2px solid #4a506f;
                                    border-bottom: 2px solid #4a506f;
                                    width: 5px;
                                    height: 5px;
                                    transition: all .3s ease;
                                    transform: translateY(-50%) rotate(-45deg);
                                }
                                &:hover {
                                    font-weight: bold;
                                    text-decoration: none;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                    .child-menu {
                        display: none;
                        font-weight: normal;
                        li {
                            padding-left: 5px !important;
                            &.active a {
                                font-weight: bold!important;
                            }
                        }
                        a::before {
                            content: none!important;
                        }
                    }
                    .accord.open {
                        .child-menu {
                            display: block;
                        }
                    }
                } 
            }
        }
    }
    .main-content {
        flex-grow: 1;
        height: 100vh;
        padding: 10px;
        padding-top: 0;
        box-sizing: border-box;
        overflow-y: auto;
        .mobile-logo {
            display: none;
            position: sticky;
            top: 0;
            height: 80px;
            padding: 15px;
            box-sizing: border-box;
            background: #2b2f44;
            z-index: 10000;
            background: #2b2f44;
            .logo {
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        .topbar {
            position: sticky;
            top: 0;
            padding-top: 15px;
            background: #2b2e44;
            z-index: 1000;
            &::after {
                content: '';
                position: absolute;
                top: 100%;
                width: 100%;
                height: 5px;
                background: linear-gradient(0deg, rgba(43, 47, 68, 0), #2b2f44);
            }
            .flex {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                flex-wrap: wrap-reverse;
                .title {
                    background: #f9b115;
                    border-bottom: 2px solid #ffd579;
                    color: #000;
                    display: inline-block;
                    border-radius: 5px;
                    margin: 0 5px 0 0;
                    padding: 0 12px;
                    box-sizing: border-box;
                    font-size: 20px;
                    line-height: 46px;
                    white-space: nowrap;
                    margin-bottom: 10px;
                }
                .account-container {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    .mobile-client {
                        display: none;
                        margin-bottom: 5px;
                        .chosen-container {
                            width: 100%;
                        }
                    }
                    .customer-info {
                        position: relative;
                        display: flex;
                        align-items: center;
                        min-width: 150px;
                        height: 48px;
                        background: #fff;
                        border-bottom: 2px solid #9a9a9a;
                        color: #000;
                        border-radius: 5px;
                        padding: 2px 4px;
                        line-height: 20px;
                        font-size: 13px;
                        cursor: pointer;
                        a {
                            width: 100%;
                            text-decoration: none!important;
                        }
                        .label {
                            color: #757575;
                            text-align: center;
                            font-weight: bold;
                        }
                        button {
                            width: 100%;
                            color: #d7dbea;
                            background: #4c5064;
                            border: 0;
                            border-bottom: 2px solid #7b809a;
                            border-radius: 3px;
                            padding: 0 10px;
                            box-sizing: border-box;
                            font-size: 12px;
                            line-height: 20px;
                            cursor: pointer;
                        }
                        &:hover button {
                            background: #63677b;
                        }
                        .locked-customer {
                            display: flex;
                            align-items: center;
                            .name {
                                font-weight: bold;
                            }
                            .customer {
                                position: relative;
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                                text-align: center;
                                .barcode {
                                    flex: 0 0 40px;
                                    width: 40px;
                                    height: 30px;
                                    margin-right: 8px;
                                    background-size: 70%;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    border: 1px solid #e4e4e4;
                                    border-radius: 5px;
                                }
                                .menu {
                                    display: none;
                                    position: absolute;
                                    min-width: 100%;
                                    top: 100%;
                                    left: 0;
                                    background: #fff;
                                    border-radius: 5px;
                                    border-top: 7px solid #353a52;
                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                    z-index: 1001;
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        top: -8px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: 0;
                                        height: 0;
                                        border: 8px solid transparent;
                                        border-bottom-color: #fff;
                                        border-top: 0;
                                    }
                                    ul {
                                        list-style-type: none;
                                        margin: 0;
                                        padding: 5px 0;
                                        li {
                                            margin: 0;
                                            padding: 0 15px;
                                            border-radius: 0;
                                            text-align: left;
                                            cursor: pointer;
                                            a {
                                                display: block;
                                                color: #2b2e44;
                                                font-size: 13px;
                                                font-weight: 500;
                                                color: #000;
                                                text-decoration: none;
                                                line-height: 30px;
                                                white-space: nowrap;
                                                user-select: none;
                                                &:visited,
                                                &:hover,
                                                &:active {
                                                    color: inherit;
                                                    text-decoration: inherit;
                                                }
                                                .icon {
                                                    margin-right: 4px;
                                                }
                                            }
                                            &.active {
                                                background: #eee;
                                                border-color: transparent;
                                                a {
                                                    font-weight: bold;
                                                    color: #000;
                                                }
                                            }
                                            &:hover {
                                                background: #ffd579;
                                                border-color: transparent;
                                            }
                                        }
                                    }
                                }
                                &:hover .menu {
                                    display: block;
                                }
                            }
                            .info {
                                margin-left: 5px;
                                text-align: center;
                                border-left: 1px solid #e4e4e4;
                                padding: 0 5px;
                                a {
                                    color: #63677b;
                                }
                            }
                        }
                    }
                    .divider {
                        margin: 0 10px;
                        height: 30px;
                        width: 2px;
                        background: #5f688a;
                    }
                    .account {
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 48px;
                        background: #fff;
                        border-bottom: 2px solid #9a9a9a;
                        color: #000;
                        border-radius: 5px;
                        padding: 2px 4px;
                        line-height: 20px;
                        font-size: 13px;
                        cursor: pointer;
                        .profile-picture {
                            flex: 0 0 40px;
                            width: 40px;
                            height: 40px;
                            background-color: #4c5164;
                            border: 1px solid #ddd;
                            border-radius: 4px;
                            margin-right: 6px;
                            padding: 8px;
                            box-sizing: border-box;
                            transition: all .3s ease;
                            .picture {
                                width: 100%;
                                height: 100%;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center center;
                            }
                        }
                        .name {
                            font-weight: bold;
                            font-size: 14px;
                        }
                        .option {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            align-items: center;
                            margin: -2px -4px -2px 6px;
                            height: 46px;
                            padding: 14px 5px;
                            box-sizing: border-box;
                            border-left: 1px solid #e0e0e0;
                            background: #e8e8e8;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                            .dot {
                                flex: 0 0 3px;
                                width: 3px;
                                height: 3px;
                                background: #000;
                            }
                        }
                        .menu {
                            display: none;
                            position: absolute;
                            min-width: 100%;
                            top: 100%;
                            right: 0;
                            background: #fff;
                            border-radius: 5px;
                            border-top: 7px solid #353a52;
                            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                            z-index: 1001;
                            &::before {
                                content: '';
                                position: absolute;
                                top: -8px;
                                right: 0;
                                width: 0;
                                height: 0;
                                border: 8px solid transparent;
                                border-bottom-color: #fff;
                                border-top: 0;
                            }
                            ul {
                                list-style-type: none;
                                margin: 0;
                                padding: 5px 0;
                                li {
                                    margin: 0;
                                    padding: 0 15px;
                                    border-radius: 0;
                                    cursor: pointer;
                                    
                                    a {
                                        display: block;
                                        color: #2b2e44;
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #000;
                                        text-decoration: none;
                                        line-height: 30px;
                                        white-space: nowrap;
                                        user-select: none;
                                        &:visited,
                                        &:hover,
                                        &:active {
                                            color: inherit;
                                            text-decoration: inherit;
                                        }
                                        .icon {
                                            margin-right: 4px;
                                        }
                                    }
                                    &.active {
                                        background: #eee;
                                        border-color: transparent;
                                        a {
                                            font-weight: bold;
                                            color: #000;
                                        }
                                    }
                                    &:hover {
                                        background: #ffd579;
                                        border-color: transparent;
                                    }
                                }
                            }
                        }
                        &:hover {
                            .profile-picture {
                                background: #5d637b;
                            }
                            .menu {
                                display: block;
                            }
                        }
                    }
                }
            }
            .breadcrumb-menu {
                color: #d7dbea;
                background: #4a506f;
                border-bottom: 2px solid #60688c;
                border-radius: 5px;
                padding: 0 5px;
                box-sizing: border-box;
                line-height: 30px;
                margin: 0 0 10px 0;
                ul {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    li {
                        position: relative;
                        padding: 0 8px;
                        font-size: 13px;
                        line-height: 30px;
                        box-sizing: border-box;
                        color: #d7dbea;
                        a {
                            color: #bec1ce;
                            text-decoration: none;
                            line-height: inherit;
                            display: inline-block;
                            &:visited, &:active {
                                color: #bec1ce;
                                text-decoration: none;
                            }
                            &:hover {
                                color: #fff;
                            }
                        }
                        &:not(:last-child)::after {
                            content: '';
                            position: absolute;
                            right: -1px;
                            top: 50%;
                            border-right: 2px solid #bec1ce;
                            border-bottom: 2px solid #bec1ce;
                            width: 5px;
                            height: 5px;
                            transition: all .3s ease;
                            transform: translateY(-50%) rotate(-45deg);
                        }
                        &::before {
                            content: '';
                            padding: 0;
                        }
                        &.active {
                            color: #fff;
                            user-select: none;
                            a {
                                font-weight: normal;
                                &:hover {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .content {
            padding: 10px;
            background: #fff;
            border-radius: 10px;
            min-height: calc(100vh - 125px);
            .mobile-menu {
                display: none;
                position: sticky;
                top: 80px;
                background: #fff;
                padding-top: 10px;
                z-index: 900;
                .divider {
                    span {
                        display: none;
                    }
                }
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li {
                        display: none;
                    }
                    li.active {
                        display: block;
                        ul li {
                            display: block;
                        }
                    }
                    .menu {
                        display: flex;
                        align-items: center;
                        padding: 4px 8px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        background: #4a506f;
                        color: #fff;
                        font-weight: bold;
                        margin-bottom: 10px;
                        text-decoration: none!important;
                        cursor: default;
                        .icon {
                            width: 18px;
                            height: 18px;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            margin-right: 5px;
                        }
                    }
                    .sub-menu ul {
                        display: flex;
                        padding: 0 10px 10px 10px;
                        margin: 0 -10px;
                        box-sizing: border-box;
                        overflow-x: auto;
                        white-space: nowrap;
                        li {
                            padding: 4px 10px;
                            border: 1px solid #e4e4e4;
                            border-radius: 5px;
                            margin-right: 5px;
                            a {
                                color: #000;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.btn-primary {
    border-color:#5f688b;
    background: #4a506f;
    &:hover, &.dropdown-toggle {
        border-color: #5f688b!important;
        background-color: #4a506f!important;
    }
    &:hover, &:focus {
        background-color: #5f688b!important;
    }
}
.btn-warning {
    color: #000;
    border-color: #b59651;
    background-color: #f9b113;
    &:hover, &:focus {
        color: #000;
        background-color: #ffc443;
    }
}
.btn-danger {
    border: 0;
    border-bottom: 2px solid #fd2f39;
    background-color: #ed1b25;
}
.btn-info {
    color: #4a506f;
    border-color: #4a506f;
    background-color: #fff;
    &:hover, &:focus {
        background-color: #e0e0e0;
        border-color: #4a506f;
        color: #4a506f;
    }
}
.btn-success {
    color: #fff;
    border-color: #4a506f;
    background-color: #2b2f44;
    &.dropdown-toggle {
        color: #fff!important;
        border-color: #4a506f!important;
        background-color: #2b2f44!important;
    }
    &:hover, &:focus {
        background-color: #4a506f!important;
    }
}
.text-default {
    color: #4a506f!important;
}
.text-danger {
    color: #ed1b25!important;
}
.paginate_button.active > a {
    border-color: #5f688b!important;
    background-color: #4a506f!important;
}
tr.selected {
    background-color: #4a506f!important;
}
.coverBlue {
    background: #4a506f;
    tr:hover {
        background: #5f688b!important;
    }
}
input.labelauty:checked + label {
    color: #fff;
    background-color: #4a506f;
}
input.labelauty:checked:not([disabled]) + label:hover {
    background-color: #5f688a;
}
.sweet-alert {
    border-radius: 20px!important;
    .sa-error {
        border-color: #ed1b25!important;
        .sa-line {
            background-color: #ed1b25!important;
        }
    }
}
.index-layout {
    @media (min-width: 992px) {
        top: unset;
        right: 15px;
        .index-panel {
            height: calc(100vh - 150px);
            background: #f9f9f9;
            border-radius: 10px;
            margin-bottom: 0;
        }
    }
}
.loader-overlay {
    background: #2b2f44;
}
@keyframes menu-active {
    0% {
        border: 2px solid #000;
    }
    50% {
        border: 2px solid #f9b115;
    }
    100% {
        border: 2px solid #000;
    }
}
